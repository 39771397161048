<template>
  <cards-container
    class="progress-bars-card double-progressbar-set-card"
    :title="title"
    :date="date"
    :redirectionLink="redirectionLink"
  >
    <template v-slot:body>
      <v-row class="mt-7">
        <v-col :cols="7" class="body pl-15 d-flex align-center">
          <div class="text-h5 d-inline-block last-report">
            <span class="text-capitalize">{{ $t("since") }}</span>
            <span class="text-lowercase"> {{ $t("last") }}</span>
            {{ $t("report") }}
          </div>
          <div class="text-h4 ml-10 d-inline-block">
            <v-list-item-title class="text-uppercase mb-2"
              >
              #{{ $t("worst") }}
              </v-list-item-title
            >
            <v-list-item-subtitle>
              <span class="incline-decline">
                <span class="icomoon icon-up"></span>
                {{data.worst}}
              </span>
            </v-list-item-subtitle>
          </div>
          <div class="text-h4 ml-10 d-inline-block">
            <v-list-item-title class="text-uppercase mb-2">
              #{{ $t("same") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="incline-decline">
                <span class="icomoon icon-straight"></span>
                {{data.same}}
              </span>
            </v-list-item-subtitle>
          </div>
          <div class="text-h4 ml-10 d-inline-block">
            <v-list-item-title class="text-uppercase mb-2">
              #{{ $t("reduce") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="incline-decline">
                <span class="icomoon icon-down"></span>
                {{ data.reduce }}
              </span>
            </v-list-item-subtitle>
          </div>
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col :cols="5" class="body custom_symptom_reported">
          <v-list-item-title class="text-uppercase mb-2">
            {{ $t("custom") }} {{ $t("symptom") }} {{ $t("reported") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span class="incline-decline text-capitalize">
              <span class="text-h5">{{ $t("fatigue") }}</span>
              {{ data.fatigue }}
            </span>
          </v-list-item-subtitle>
        </v-col>
      </v-row>
    </template>
  </cards-container>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";

export default {
  name: "ESASCard",

  props: {
    title: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/esas-card.scss">
</style>